import logo from "./logo.svg";
import "./App.css";
import stores from "./store.json";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const Trivia = () => {
  const [questions, setQuestions] = useState([]);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [res, setRes] = useState(undefined);
  const activeQuestion = !!questions.length && questions[indexQuestion];

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    const results = await axios.get(
      "https://opentdb.com/api.php?amount=50&category=9&difficulty=medium"
    );
    const q = results.data.results;
    setQuestions(
      q.map((question) => ({
        category: question.category,
        question: question.question,
        difficulty: question.difficulty,
        answers: [
          {
            id: 0,
            label: question.correct_answer,
            isCorrect: true,
            sort: Math.random(),
          },
          ...question.incorrect_answers.map((a, i) => ({
            id: i + 1,
            label: a,
            isCorrect: false,
            sort: Math.random(),
          })),
        ].sort((a, b) => a.sort - b.sort),
      }))
    );
  };

  const renderColor = (difficulty) => {
    switch (difficulty) {
      case "easy":
        return "green";
      case "medium":
        return "yellow";
      case "hard":
        return "red";
      default:
        return "grey";
    }
  };

  const handleResponse = (response) => {
    setRes(response);
  };

  useEffect(() => {
    if (typeof res !== "undefined") {
      setTimeout(() => {
        setIndexQuestion(indexQuestion + 1);
        setRes(undefined);
      }, 3000);
    }
  }, [res]);

  console.log("questions", questions);
  console.log("activeQuestion", activeQuestion);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      {activeQuestion && (
        <div>
          <div
            style={{
              border: `2px solid ${renderColor(activeQuestion.difficulty)}`,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: 24,
              borderRadius: 8,
              minWidth: 400,
            }}
          >
            <div style={{ fontSize: 14, color: "#444" }}>
              {activeQuestion.category}
            </div>
            <div>{cleanText(activeQuestion.question)}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "2%",
              marginTop: 32,
            }}
          >
            {activeQuestion.answers.map((answer) => {
              const isAnswered = res === answer.id;
              return (
                <div
                  style={{
                    width: "46%",
                    textAlign: "center",
                    border: "2px solid #ccc",
                    padding: "1%",
                    marginBottom: "1%",
                    borderRadius: 8,
                    backgroundColor:
                      typeof res !== "undefined"
                        ? isAnswered
                          ? answer.isCorrect
                            ? "green"
                            : "red"
                          : answer.isCorrect
                          ? "green"
                          : null
                        : null,
                    cursor: "pointer",
                  }}
                  onClick={() => handleResponse(answer.id)}
                >
                  {cleanText(answer.label)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const cleanText = (string) => {
  if (!string) return "";
  return string.split("&quot;").join('"').split("&#039;").join("'");
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export default Trivia;
